import React from 'react';
import {
  useDisclosure,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  HStack,
  Image,
  VStack,
  Input,
  Icon,
  Text,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Error from '../components/Error';
import api from '../utils/api';
import { QRCodeCanvas } from 'qrcode.react';
import { useQuery } from 'react-query';
import { FaDownload, FaMapPin, FaShare, FaLink } from 'react-icons/fa';
import { RWebShare } from 'react-web-share';
import useUserState from '../stores/user';
import Linkify from 'react-linkify';
import { linkify } from '../utils/venues';
import GoogleMap from 'google-maps-react-markers';
import GameCard from '../components/GameCard';
import ZonePicker from '../components/ZonePicker';
import dayjs from 'dayjs';

const Marker = ({ label }) => (
  <HStack style={{ marginLeft: '-24px', marginTop: '-24px' }}>
    <Icon as={FaMapPin} color="orange" w="36px" h="36px" />
    <Text
      bg="muted"
      style={{
        whiteSpace: 'nowrap',
        fontSize: '24px',
        borderRadius: '24px',
        padding: '4px 10px 4px 10px',
      }}
    >
      {label}
    </Text>
  </HStack>
);

const ConferencePage = () => {
  const { conferenceId } = useParams();
  const user = useUserState(state => state.user);
  const navigate = useNavigate();
  const cancelRef = React.useRef();
  const textRef = React.useRef();
  const [done, setDone] = React.useState(false);
  const [error, setError] = React.useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const conferenceQuery = useQuery(['conference', conferenceId], () =>
    api('/conferences/' + conferenceId)
  );
  const con = conferenceQuery.data;

  const downloadQR = () => {
    const canvas = document.getElementById('qr');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${con.name}-AdventureNexus.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  let borderColor = null;
  let boxShadow = null;
  if (con && con.attending) {
    borderColor = 'green';
    boxShadow = '0 0 10px 2px ' + borderColor;
  }

  const register = () => {
    api('/conferences/' + conferenceId + '/register', {
      code: textRef.current.value,
    }).then(res => {
      if (res.error) {
        setError(res.error);
      } else {
        setError(null);
        setDone(true);
        conferenceQuery.refetch();
      }

    });
  };

  return (
    <>
      <Box>
        <Header />
        <HStack justify="space-between" mx={4}>
          <Link to={'/conferences'}>&lt; Back</Link>
        </HStack>
        <Flex direction={'column'} w={'100%'} alignItems={'center'}>
          {con && (
            <Card
              maxWidth="100%"
              m={0}
              mb={6}
              style={{
                display: 'inline-block',
                overflow: 'hidden',
                width: '80%',
                corder: borderColor,
                boxShadow: boxShadow,
                maxWidth: '1024px',
              }}
            >
              {con.banner && (
                <Image
                  objectFit="cover"
                  src={
                    con.banner.match(/^(data:image|http)/)
                      ? con.banner
                      : process.env.REACT_APP_IMAGE_BASE + con.banner
                  }
                  alt={con.name}
                  w="100%"
                  h="300px"
                />
              )}
              {con.attending && (
                <Text
                  fontSize="sm"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    marginLeft: '-3em',
                    width: '6em',
                    color: 'white',
                    zIndex: 1,
                    borderRadius: '0 0 15px 15px',
                    textAlign: 'center',
                    backgroundColor: borderColor,
                    boxShadow: boxShadow,
                  }}
                >
                  Registered
                </Text>
              )}

              <CardBody style={{ color: 'white' }} m={4}>
                <HStack justifyContent="space-between" mb={4}>
                  <Heading size="lg"> {con.name} </Heading>
                  <Heading size="sm">
                    {dayjs(con.start_date).format('M/D/YY')}
                    {con.start_date !== con.end_date &&
                      ' - ' + dayjs(con.end_date).format('M/D/YY')}
                  </Heading>
                </HStack>
                <HStack justify="space-between" gap={2}>
                  <HStack color="orange" alignItems="middle" gap={4}></HStack>
                </HStack>
                <HStack justify="space-between" align="start">
                  <VStack align="start" ml={2} w="100%">
                    <a href="#viewmap" color="muted">
                      <Text color="muted">
                        <Icon as={FaMapPin} mr={2} color="white" />
                        {con.location}
                      </Text>
                    </a>
                    {con.website && (
                      <Link
                        onClick={e => e.stopPropagation()}
                        target="_blank"
                        color="muted"
                        to={linkify(con.website)}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                      >
                        <Text
                          color="muted"
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <Icon as={FaLink} mr={2} color="white" />
                          {con.website?.replace(/https?:\/\//, '')}
                        </Text>
                      </Link>
                    )}
                    {con.code && con.attending && (
                      <Text mt={4} color="orange" fontWeight="bold">
                        You registered to attend on{' '}
                        {dayjs(con.attending).format('M/D/YY')}!
                      </Text>
                    )}
                    {con.code && !con.attending && (
                      <Button mt={4} onClick={() => onOpen()}>
                        Register for {con.name}
                      </Button>
                    )}
                  </VStack>
                  <VStack align="end" ml={2} w="100%">
                    <Box color="orange">
                      {con && (
                        <RWebShare
                          data={{
                            text: 'Find places to play RPG, CCG, Table Top and other games on The Adventure Nexus',
                            url: document.location.href,
                            title: con.name,
                          }}
                          disableNative={false}
                          onClick={() => console.log('shared successfully!')}
                        >
                          <button>
                            <Icon as={FaShare} /> Share
                          </button>
                        </RWebShare>
                      )}
                    </Box>
                    <VStack
                      onClick={downloadQR}
                      style={{ cursor: 'pointer', textAlign: 'center' }}
                      mt={2}
                    >
                      <Box
                        style={{ position: 'relative' }}
                        mb={0}
                        h={120}
                        w={120}
                      >
                        <QRCodeCanvas
                          id="qr"
                          size={256}
                          style={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: '100%',
                          }}
                          value={
                            document.location.origin + '/conferences/' + con.id
                          }
                        />
                      </Box>
                      <HStack>
                        <Icon boxSize={4} as={FaDownload} />
                        <Text>Download</Text>
                      </HStack>
                    </VStack>
                  </VStack>
                </HStack>
                <Box mt={8} className="linkify">
                  <Text fontWeight="bold" color="white">
                    Description
                  </Text>
                  <Linkify>
                    <Text style={{ whiteSpace: 'pre-wrap' }} color="muted">
                      {con.description}
                    </Text>
                  </Linkify>
                </Box>
              </CardBody>
            </Card>
          )}

          {con && con.games && con.games.length > 0 && (
            <>
              <Heading size="md">Official Games hosted at {con.name}</Heading>
              <Flex wrap="wrap" justifyContent="center">
                {con.games.map(game => (
                  <GameCard
                    key={game.id}
                    game={game}
                    callback={() => navigate(`/games/${game.id}`)}
                  />
                ))}
              </Flex>
            </>
          )}

          {con && con.lat && (
            <Box px={1} w="100%" id="viewmap">
              <Heading size="md" align="center" mt={8}>
                Conference Location
              </Heading>
              <GoogleMap
                apiKey={process.env.REACT_APP_MAPS_KEY}
                defaultCenter={{
                  lat: parseFloat(con.lat),
                  lng: parseFloat(con.lon),
                }}
                defaultZoom={14}
                mapMinHeight="600px"
              >
                <Marker
                  lat={parseFloat(con.lat)}
                  lng={parseFloat(con.lon)}
                  label={con.name}
                  markerId={1}
                  key={1}
                />
              </GoogleMap>
            </Box>
          )}
        </Flex>
        <ZonePicker />
      </Box>

      {con && (
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              bg="#272639"
              borderColor="muted"
              borderWidth={4}
              maxWidth="xl"
              w="xl"
              h="md"
              maxHeight="md"
            >
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Register for {con.name}
              </AlertDialogHeader>

              {!done && (
                <AlertDialogBody>
                  {error && <Error>{error}</Error>}
                  <FormControl variant="floating" pb={6} isRequired>
                    <Input placeholder=" " ref={textRef} />
                    <FormLabel>Please enter the conference registration code you receiveed.  </FormLabel>
                  </FormControl>
                </AlertDialogBody>
              )}
              {done && (
                <AlertDialogBody>
                  <Text size="lg" fontWeight="bold" color="orange">
                    You have successfully registered for {con.name}.
                  </Text>
                </AlertDialogBody>
              )}

              <AlertDialogFooter>
                {done && (
                  <Button variant="outline" ref={cancelRef} onClick={onClose}>
                    Close
                  </Button>
                )}
                {!done && (
                  <>
                    <Button variant="outline" ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button onClick={register} ml={3}>
                      Register
                    </Button>
                  </>
                )}
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default ConferencePage;
